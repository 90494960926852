export function getBaseURL (state) {
  if (state.config) {
    const username = state.config.user_name;
    const host = state.config.account_host;
    return `https://${host}/user/${username}/api/v4`;
  } else {
    //return 'https://public.carto.com/api/v4';
    console.trace('baseURL');
    return 'https://dev2.epsilonmetrics.ru/api/v4';
  }
}
