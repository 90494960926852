var template = require('./quote.tpl');

var QUOTES = [
  { quote: "Географы никогда не теряются. Они просто занимаются случайными полевыми работами.", author: "Николас Крисман" },
  { quote: "География - это просто замедленная физика с парой застрявших в ней деревьев.", author: "Терри Пратчетт" },
  { quote: "Не все блуждающие теряются.", author: "Дж. Р. Р. Толкин" },
  { quote: "В этой империи искусство картографии достигло такого совершенства, что карта одной провинции занимала весь город целиком.", author: "Хорхе Луис Борхес" },
  { quote: "Крестиком отмечено это место", author: "Индиана Джонс" },
  { quote: "Это черепахи на всем пути вниз.", author: null },
  { quote: "Помните: куда бы вы ни пошли, вы всегда там.", author: null },
  { quote: "Без географии вы нигде не окажетесь!", author: "Джимми Баффет" },
  { quote: "наша земля - это глобус, / чью поверхность мы исследуем /<br />никакая карта не заменит ее / но просто попробуйте проследить ее", author: "Стив Уотерман" },
  { quote: "Все где-то происходит.", author: "Доктор Кто" },
  { quote: "Карта - величайшая из всех эпических поэм. Его линии и цвета свидетельствуют о воплощении великих мечтаний.", author: "Гилберт Х. Гросвенор" },
  { quote: "Все связано со всем остальным, <br />но близкие вещи связаны больше, чем отдаленные.", author: "Первый закон географии Тоблера" },
  { quote: "Здесь водятся драконы", author: null },
  { quote: "Здесь будут драконы", author: null },
  { quote: "Встаньте на том месте, где вы живете / Теперь повернитесь лицом на север / <br />Подумайте о направлении / Удивитесь, почему вы не сделали этого раньше", author: "R.E.M" },
  { quote: "Достоинство карт в том, что они показывают, что можно сделать в условиях ограниченного пространства, они предвидят, что там может произойти все, что угодно.", author: "Хосе Сарамаго" }
];

/**
 * Random quote
 */
module.exports = function () {
  var idx = Math.round(Math.random() * (QUOTES.length - 1));

  return template(QUOTES[idx]);
};
